import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’re excited to be chosen as a 2018 Cool Vendor in AI for Conversational Platforms. Each year Gartner examines and identifies emerging vendors that application leaders should watch “in order to take advantage of the disruption they will bring to all markets.” As brands and developers figure out how to leverage chatbots and voice applications for customer engagement, Dashbot offers actionable, advanced insights to optimize conversational experiences and conversions.`}</p>
    <p>{`Dashbot is the leading conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable insights. In addition to traditional analytics like engagement and retention, we provide bot and voice specific analytics and tools including detailed session transcripts, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/features/phrase-clustering/"
      }}>{`NLP optimization`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/05/02/conversation-paths/"
      }}>{`conversation paths`}</a>{`. We also have interactive tools to take action on the data, like our live-person takeover, broadcast messaging, and `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/06/20/chatbot-marketing-custom-facebook-audience/"
      }}>{`audience builder`}</a>{`. If you want to analyze and optimize your chatbots or voice applications, shoot us a note at `}<a parentName="p" {...{
        "href": "mailto:contact@dashbot.io"
      }}>{`contact@dashbot.io`}</a>{` so we can help you!`}</p>
    <p>{`Learn what makes Dashbot valuable to brands and developers building conversational products `}<a parentName="p" {...{
        "href": "https://www.gartner.com/doc/3883864/cool-vendors-ai-conversational-platforms"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      